import React from "react";
import moment from 'moment';

const BidTable = ({ trips }) => {
  const tableHeaders = [
    "From",
    "To",
    "Car Type",
    "Date",
    "Trip Status"
  ];

  return (
    <div className="mt-10 w-full overflow-hidden shadow-lg rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {tableHeaders.map((header, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {trips.map((trip, index) => (
            <tr key={index} className="hover:bg-gray-50 transition-colors duration-200">
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">
                  {trip.fromLocation?.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {trip.toLocation?.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {trip.address?.city}
                </div>
                <div className="text-sm text-gray-500">
                  {trip.vehicleType}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {moment(trip.startDate).format('ll')} {trip.startTime}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 capitalize inline-flex text-xs leading-5 font-semibold rounded-full ${
                  trip.tripStatus === 'completed' ? 'bg-green-100 text-green-800' :
                  trip.tripStatus === 'canceled' ? 'bg-red-100 text-red-800' :
                  'bg-yellow-100 text-yellow-800'
                }`}>
                  {trip.tripStatus}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BidTable;