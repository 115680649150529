import React, { useState, useContext, useEffect } from "react";
import Conformation from "../common/conformation";
import AuthService from "../../services/owner/AuthService";
import AuthContext from "../../shared/AuthContext";
import useAuthentication from "../../components/HOC/useAuthentication";
import ImageS3 from "../../components/common/ImageS3";



const DriverInfomation = (props) => {
  const [visibleConformation, setVisibilityConformation] = useState(false);
  const { user, setUser } = useAuthentication();

  console.log("props=>", props)

  const getName = () => {
    let name = "# ";
    if (props?.driveinfo?.name) return props?.driveinfo?.name;
    props?.driveinfo?.myOwners.forEach((x, i) => {
      name = name + x?.name + " #";
    });
    return name;
  };

  const getProfile = () => {
    let name = "# ";
    if (props?.driveinfo?.profile?.includes("digitaloceanspaces"))
      return props?.driveinfo?.profile;
    if (
      props?.driveinfo?.myOwners?.length > 0 &&
      props?.driveinfo?.myOwners[0]?.profile
    )
      return props?.driveinfo?.myOwners[0]?.profile;
    return props?.driveinfo?.profile;
  };
  return (
    <div className={"bg-white w-1/2 shadow-xl rounded p-5 mt-10 mr-6 ml-5 "}>
      {visibleConformation && (
        <Conformation
          title={"Verify Driver"}
          msg={`Are you sure to ${props.driveinfo.isVerifiedAsDriver? 'cancel verification?':'Verify?'}`}
          status={props.driveinfo.isVerifiedAsDriver}
          confirm={() => {
            AuthService.verifyDriver(user?.user?._id, props.driveinfo._id).then(
              (data) => {
                if (props.refresh) {
                  props.setshowdriveinfo(!props.showdriveinfo);
                  props.refresh();
                }
              }
            );
          }}
        />
      )}

      <div className="  flex flex-row justify-between  rounded-tl-lg rounded-tr-lg">
        <p className="font-semibold text-gray-800"></p>
        <svg
          onClick={() => props.setshowdriveinfo(!props.showdriveinfo)}
          className="w-6 h-6 cursor-pointer "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>
      <div>
        <div>
          <div className="dashboard_profile  pt-6">
            {/* <img
              className="inline-block h-14 w-14 rounded-full"
              // src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              src={props?.driveinfo?.profile}
              alt=""
            /> */}
            <ImageS3
              uri={getProfile()}
              style={"h-24 w-24 rounded-full"}
            />
          </div>
          <div className="mt-12">
            <tr>
              <td className=" ">
                <div className="text-sm font-medium text-gray-900">Name :</div>
              </td>
              <td>
                <div className="ml-2  text-sm font-medium text-gray-900">
                  {getName()}
                </div>
              </td>
            </tr>
            <tr>
              <td className=" ">
                <div className="text-sm font-medium text-gray-900">Phone :</div>
              </td>
              <td>
                <div className="ml-2  text-sm font-medium text-gray-900">
                  {props?.driveinfo?.mobile}
                </div>
              </td>
            </tr>
            <tr>
              <td className="">
                <div className=" text-sm font-medium text-gray-900">City :</div>
              </td>
              <td>
                <div className="ml-2 text-sm font-medium text-gray-900">
                  {props?.driveinfo?.address?.city}
                </div>
              </td>
            </tr>
            {props?.tableFor != "passenger" && (
              <tr>
                <td className="">
                  <div className="text-sm font-medium text-gray-900">
                    Status :
                  </div>
                </td>
                <td>
                  {props.driveinfo.isVerifiedAsDriver ? (
                    <div className="ml-2 text-sm font-medium text-gray-900">
                      Verified
                    </div>
                  ) : (
                    <div className="ml-2 text-sm font-medium text-gray-900">
                      Not verified
                    </div>
                  )}
                </td>
              </tr>
            )}
            {/* <tr>
              <td className="">
                <div className="text-sm font-medium text-gray-900">NID:</div>
              </td>
              <td>
                <div className="ml-2 text-sm font-medium text-gray-900">
                  <img src={props?.driveinfo?.docs.nidFont.defaultUri} alt="Driver's Photo" />
                </div>
              </td>
            </tr>
            <tr>
              <td className="">
                <div className="text-sm font-medium text-gray-900">Driving License:</div>
              </td>
              <td>
                <div className="ml-2 text-sm font-medium text-gray-900">
                  <img src={props?.driveinfo?.docs.drivingLicense.defaultUri} alt="Driving License Photo" />
                </div>
              </td>
            </tr> */}

            {props?.isDriver && (
              <React.Fragment>
                <div className="text-sm font-medium text-gray-900">
                  NID:
                </div>
                <div className="ml-2 text-sm font-medium text-gray-900">
                  <img
                    src={props?.driveinfo?.docs?.nidFont?.defaultUri}
                    alt="Driver's Photo"
                  />
                </div>
                <div className="text-sm font-medium text-gray-900">
                  Driving License:
                </div>
                <div className="ml-2 text-sm font-medium text-gray-900">
                  <img
                    src={props?.driveinfo?.docs?.drivingLicense?.defaultUri}
                    alt="Driving License Photo"
                  />
                </div>
              </React.Fragment>
            )}

            {props?.tableFor != "passenger" && (
              <button
              onClick={() => setVisibilityConformation(!visibleConformation)}
              className={`mt-5 text-white font-bold py-2 px-4 rounded ${
                props.driveinfo.isVerifiedAsDriver
                  ? 'bg-red-600 hover:bg-red-700'
                  : 'bg-green-600 hover:bg-green-700'
              }`}
            >
              {props.driveinfo.isVerifiedAsDriver ? (
                <span>Cancel Verification</span>
              ) : (
                <span>Verify</span>
              )}
            </button>
            )}
          </div>
        </div>

        {props.driveinfo.assignTrip && (
          <div>
            <h3 className=" mt-5  my-2 text-sm font-medium text-gray-900">
              Assigned Car info
            </h3>
            <div className=" p-1  bg-indigo-100 w-full mb-5">
              <tr>
                <td>
                  <div className="ml-2 text-sm font-medium text-gray-900">
                    Name :
                  </div>
                </td>
                <td>
                  <div className=" ml-2 text-sm font-medium text-gray-900">
                    {props.driveinfo.assignTrip.vehicleDetails.name}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="ml-2 text-sm font-medium text-gray-900">
                    Car no :
                  </div>
                </td>
                <td>
                  <div className=" ml-2 text-sm font-medium text-gray-900">
                    {props.driveinfo.assignTrip.vehicleDetails.model}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className=" ml-2 text-sm font-medium text-gray-900">
                    Owner :
                  </div>
                </td>
                <td>
                  <div className=" ml-2  text-sm font-medium text-gray-900">
                    {props.driveinfo.assignTrip.owner.firstName}{" "}
                    {props.driveinfo.assignTrip.owner.lastName}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="ml-2 text-sm font-medium text-gray-900">
                    Seat :
                  </div>
                </td>
                <td>
                  <div className="ml-2  text-sm font-medium text-gray-900">
                    Max {props.driveinfo.assignTrip.vehicleDetails.seat} seats
                  </div>
                </td>
              </tr>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DriverInfomation;
