import React from 'react';

function TWModal({ handleModal, children }) {
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl mx-4 sm:mx-auto relative">
        <button
          onClick={() => handleModal(false)}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-500 z-20"
        >
          <span className="sr-only">Close</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="max-h-[80vh] overflow-y-auto p-6 pt-10">
          {children}
        </div>
      </div>
    </div>
  );
}

export default TWModal;
