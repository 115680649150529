import React, { useState } from "react";
import Popup from "../../screen/Transaction/transaction_popup";
import moment from "moment";
import JSONView from "../common/JSONView";

const TransTable = ({ transations }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  console.log(transations);

  const togglePopup = (item) => setSelectedItem(item);
  const closePopup = () => setSelectedItem(null);

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden my-8 mr-16">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Trans. Date</th>
              <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">From - To</th>
              <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Trip ID</th>
              <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transaction ID</th>
              <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Refund</th>
              <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transaction For</th>
              <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {transations.map((item, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-3 py-4 whitespace-nowrap text-xs text-gray-900">{moment(item.lastUpdatedAt).format('DD MMM YY HH:mm')}</td>
                <td className="px-3 py-4 whitespace-nowrap uppercase text-xs text-gray-900">{(item?.transactionFor?.trip?.fromLocation?.name || 'N/A')} - {(item?.transactionFor?.trip?.toLocation?.name || 'N/A')} </td>
                <td className="px-3 py-4 whitespace-nowrap uppercase text-xs text-gray-900">{(item?.transactionFor?.trip?._id || 'N/A').slice(0, 8)}...</td>
                <td className="px-3 py-4 whitespace-nowrap uppercase text-xs text-gray-900">{item._id.slice(0, 8)}...</td>
                <td className="px-3 py-4 whitespace-nowrap text-xs text-gray-900">{item.paidAmount}</td>
                <td className="px-3 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${item.isRefunded ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"}`}>
                    {item.isRefunded ? "Yes" : "No"}
                  </span>
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-xs text-gray-900">{item.transactionForType}</td>
                <td className="px-3 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs capitalize leading-5 font-semibold rounded-full ${item.status === "success" ? "bg-green-100 text-green-800" : item.status === "failed" ? "bg-red-100 text-red-800" : "bg-yellow-100 text-yellow-800"}`}>
                    {item.status}
                  </span>
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-xs font-medium">
                  <button
                    onClick={() => togglePopup(item)}
                    className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                  >
                    <svg className="h-4 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedItem && (
        selectedItem?.transactionFor?.trip ? (
          <Popup
            isOpen={true}
            onClose={closePopup}
            detailsInformation={selectedItem}
          />
        ) : (
          <JSONView data={selectedItem} onClose={closePopup} />
        )
      )}
    </div>
  );
};

export default TransTable;