import React, { useState, useEffect } from "react";

import FileService from "../../services/FileService";
import { PhotoProvider, PhotoView } from "react-photo-view";
// import "react-photo-view/dist/react-photo-view.css";
const ImageS3 = ({ uri, style }) => {
  const [s3PubicUri, setS3PubicUri] = useState(null);

  let uri2 =
    "https://img.freepik.com/premium-vector/avatar-profile-icon_188544-4755.jpg";

  useEffect(() => {
    if (uri) {
      getShowCarImage().catch();
    }
  }, [uri]);

  const getShowCarImage = async () => {
    const res = await FileService.getDownloadableLink(uri);
    // console.log("getres", res);
    if (res) {
      setS3PubicUri(res);
    }
  };

  return (
    <div>
      {/* {s3PubicUri && <img className={style} src={s3PubicUri} />} */}
      {s3PubicUri && (
        <PhotoProvider>
            <PhotoView src={s3PubicUri}>
              <img className={style} src={s3PubicUri} alt="" />
            </PhotoView>
        </PhotoProvider>
      )}
      {/* <img className={style} src={uri2} /> */}
    </div>
  );
};

export default ImageS3;
