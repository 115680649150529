import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout/index";
import { sideBarItems } from "../../utils";
import AuthService from "../../services/owner/AuthService";
import PassengerService from "../../services/PassengerService";
import NotificationService from "../../services/NotificationService";

function Notifications() {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userType, setUserType] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [userList, setUserList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (userType) {
      fetchUserData();
    }
  }, [userType]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const fetchUserData = () => {
    const service = userType === "passenger" ? PassengerService.getPassenger : AuthService.getOwnerList;
    service()
      .then((data) => {
        const users = data.map((user) => ({
          id: user._id,
          name: user.name,
          token: user.firebaseTokens,
          display: `${user.name} (${user.mobile})`,
        }));
        setUserList(users);
        setSelectedUsers([]);
      })
      .catch((error) => {
        console.error(`Error fetching ${userType} list:`, error);
      });
  };

  const handleSelectUsers = (userName, token, id) => {
    setSelectedUsers((prev) => {
      const isSelected = prev.some((user) => user.name === userName);
      return isSelected
        ? prev.filter((user) => user.name !== userName)
        : [...prev, { id, name: userName, token }];
    });
  };

  const handleSelectAll = (event) => {
    setSelectedUsers(event.target.checked ? userList : []);
  };

  const sendMessage = async () => {
    // check all fields are filled
    if (!title || !message || selectedUsers.length === 0) {
      alert("Please fill all fields and select users");
      return;
    }
    try {
      const tokens = [...new Set(selectedUsers.flatMap((user) => user.token?.map((t) => t.token) || []))];
      const payload = {
        msg: { title, body: message, image, imageUrl },
        tokens,
      };
      await NotificationService.postNotification(payload);
      console.log("Messages sent successfully");
    } catch (error) {
      console.error("Error sending messages:", error);
    }
  };

  const handleUploadImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (file.size > 2 * 1024 * 1024) {
          setImageError("Image size must be less than 2MB");
        } else {
          setImage(event.target.result);
          setImageError(null);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Layout title={sideBarItems.notifications.key}>
      <div className="bg-gray-100 min-h-screen p-8">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold mb-6">Send Notifications</h2>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">User Type</label>
            <select
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Select an option</option>
              <option value="passenger">Passenger</option>
              <option value="partner">Partner</option>
            </select>
          </div>

          {userType && (
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">Select Users</label>
              <div className="relative" ref={dropdownRef}>
                <div
                  onClick={() => setShowDropdown(!showDropdown)}
                  className="w-full p-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 cursor-pointer min-h-[40px] flex flex-wrap items-center gap-2"
                >
                  {selectedUsers.length > 0 ? (
                    selectedUsers.map((user) => (
                      <div key={user.id} className="bg-indigo-100 text-indigo-800 text-sm rounded-full px-3 py-1 flex items-center">
                        {user.name}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectUsers(user.name, user.token, user.id);
                          }}
                          className="ml-2 text-indigo-600 hover:text-indigo-800 focus:outline-none"
                        >
                          x
                        </button>
                      </div>
                    ))
                  ) : (
                    <span className="text-gray-500">Select users</span>
                  )}
                </div>
                {showDropdown && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                    <div className="p-2 border-b">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={selectedUsers.length === userList.length}
                          className="mr-2"
                        />
                        Select All
                      </label>
                    </div>
                    {userList.map((user) => (
                      <div key={user.id} className="p-2">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            checked={selectedUsers.some((selectedUser) => selectedUser.name === user.name)}
                            onChange={() => handleSelectUsers(user.name, user.token, user.id)}
                            className="mr-2"
                          />
                          {user.display}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter notification title"
              maxLength={70}
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              rows="4"
              placeholder="Enter your message..."
              maxLength={1000}
            ></textarea>
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Upload Image</label>
            <input
              type="file"
              onChange={handleUploadImageChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            />
            {imageError && <p className="mt-2 text-sm text-red-600">{imageError}</p>}
            {image && <img src={image} alt="Preview" className="mt-4 max-w-xs rounded-md" />}
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Image URL</label>
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter image URL"
            />
            {imageUrl && <img src={imageUrl} alt="Preview" className="mt-4 max-w-xs rounded-md" />}
          </div>

          <div className="flex justify-end space-x-4">
            <button
              onClick={() => {
                setMessage("");
                setTitle("");
                setImage(null);
                setImageUrl(null);
              }}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Clear
            </button>
            <button
              onClick={() => sendMessage()}
              className=" inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md shadow-sm transition-all duration-150 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 active:bg-indigo-800 active:scale-95 "
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
              </svg>
              Send Notification
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Notifications;