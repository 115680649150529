import axios from 'axios';
import _ from 'lodash';

const instance = axios.create();

instance.interceptors.response.use(null, (rejection) => {
  if (rejection.response.status === 401){
    localStorage.setItem('admin', JSON.stringify(null));
  }
 
  return Promise.reject(rejection);
});

instance.interceptors.request.use(
  function (config) {
    const userString = localStorage.getItem('admin');
    const user = JSON.parse(userString);
   
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
    }
    if (user?.accessToken) {
      headers.Authorization=`Bearer ${user?.accessToken}`;
    }
    config.headers = headers;
     
    // alert(JSON.stringify(config))
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
