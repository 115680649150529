import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import Layout from "../../components/Layout/index";
import { sideBarItems } from "../../utils";
import AdminService from "../../services/admin/AdminService";
import DriverInfomation from "../../components/DriverList/driverInfo";
import OwnerInfomation from "../../screen/Owners/ownerInfo";
import ImageS3 from "../../components/common/ImageS3";
import _ from "lodash";
import InitiatedTrip from "./tripList";
import CarInfo from "../../components/common/car_info_model";

const Dashboard = () => {
  const [driverList, setDriverList] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [notVarifiedDriver, setNotVarifiedDriver] = useState([]);
  const [notVarifiedOwner, setNotVarifiedOwner] = useState([]);
  const [showdriveinfo, setshowdriveinfo] = useState(false);
  const [showOwnerInfo, setShowOwnerInfo] = useState(false);
  const [driveinfo, setDriveinfo] = useState();
  const [ownerInfo, setOwnerInfo] = useState();
  const [carInfo, setCarInfo] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [search, setSearch] = useState("");
  const [searchOwner, setSearchOwner] = useState("");
  const [filteredDriverVerificationPendingList, setFilteredDriverVerificationPendingList,] = useState([]);
  const [filteredOwnerVerificationPendingList, setFilteredOwnerVerificationPendingList,] = useState([]);

  console.log("dashboard Data=>", dashboardData)

  useEffect(() => {
    console.log("filteredDriverVerificationPendingList", filteredDriverVerificationPendingList)
  }, [filteredDriverVerificationPendingList])

  const ShowDriveSecthom = (item) => {
    setshowdriveinfo(true);
    setDriveinfo(item);
  };

  const ShowOwnerSecthom = (item) => {
    setShowOwnerInfo(true);
    setOwnerInfo(item);
  };

  const handleDriveEdit = (item) => {
    setshowdriveinfo(true);
    ShowDriveSecthom(item);
  };
  const handleOwnerEdit = (item) => {
    // setShowOwnerInfo(true);
    ShowOwnerSecthom(item);
  };
  useEffect(() => {
    driverApi();
    ownerApi();
    dashboardDataApi();
  }, []);

  const driverApi = () => {
    AdminService.getDrivers().then((data) => {
      setDriverList(data.data);
      console.log("drivers:", data);
      let driverData = data.data;
      const drivers = driverData.filter((item) => !item.isVerifiedAsDriver);
      setNotVarifiedDriver(drivers);
    });
  };

  const ownerApi = () => {
    AdminService.getOwners().then((data) => {
      setOwnerList(data.data);
      let ownerData = data.data;
      const owners = ownerData.filter((item) => !item.isVerifiedAsOwner);
      setNotVarifiedOwner(owners);
    });
  };

  const dashboardDataApi = () => {
    AdminService.dashBoardData().then((data) => {
      setDashboardData(data.data);
    });
  };

  const handleChangeSearch = (searchQuery) => {
    let filteredDriverVerificationPendingList = _.filter(
      notVarifiedDriver,
      (item) => {
        return item?.name?.match(new RegExp(searchQuery, "gi")) || item?.mobile?.match(new RegExp(searchQuery, "gi"));
      }
    );
    setFilteredDriverVerificationPendingList(
      filteredDriverVerificationPendingList
    );
  };
  const handleChangeSearchOwner = (searchQuery) => {
    let filteredOwnerVerificationPendingList = _.filter(
      notVarifiedOwner,
      (item) => {
        return item?.name?.match(new RegExp(searchQuery, "gi")) || item?.mobile?.match(new RegExp(searchQuery, "gi"));
      }
    );
    setFilteredOwnerVerificationPendingList(
      filteredOwnerVerificationPendingList
    );
  };

  const handleChangeSearchLocal = (e) => {
    setSearch(e.target.value);
    if (handleChangeSearch) {
      handleChangeSearch(e.target.value);
    }
  };

  const handleChangeSearchLocalOwner = (e) => {
    setSearchOwner(e.target.value);
    if (handleChangeSearchOwner) {
      handleChangeSearchOwner(e.target.value);
    }
  };

  const showVihicleInfo = (item) => {
    console.log("item=>", item)
    setVisibleModal(true);
    setCarInfo(item);
  }

  const handleModal = (isVisible) => {
    setVisibleModal(isVisible)
  }

  return (
    <Layout
      title={sideBarItems.dashboard.key}
      modal={<CarInfo handleModal={handleModal} ownerInfo={ownerInfo} carInfo={carInfo} />}
      visibleModal={visibleModal}
      handleModal={handleModal}
      noMargin
    >
      <div className="mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">Dashboard Overview</h1>

        {/* Stats Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <StatCard title="Total Trips" value={dashboardData?.totalCompleted?.length} icon="🚗" />
          <StatCard title="Total On Going" value={dashboardData?.running?.length} icon="🚀" />
          <StatCard title="Upcoming Trips (1 month)" value={dashboardData?.upComing?.length} icon="📅" />
        </div>

        {/* Verification Pending Lists */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
          <VerificationList
            title="Driver Verification Pending"
            data={filteredDriverVerificationPendingList || notVarifiedDriver}
            searchValue={search}
            onSearchChange={handleChangeSearchLocal}
            onRefresh={driverApi}
            onEdit={handleDriveEdit}
          />
          <VerificationList
            title="Owner Verification Pending"
            data={filteredOwnerVerificationPendingList || notVarifiedOwner}
            searchValue={searchOwner}
            onSearchChange={handleChangeSearchLocalOwner}
            onRefresh={ownerApi}
            onEdit={handleOwnerEdit}
          />
        </div>

        {/* Initiated Trips */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Initiated Trips</h2>
          <InitiatedTrip data={dashboardData.initiated} />
        </div>
      </div>

      {/* Modals */}
      {showdriveinfo && (
        <Modal onClose={() => setshowdriveinfo(false)}>
          <DriverInfomation
            driveinfo={driveinfo}
            setshowdriveinfo={setshowdriveinfo}
            refresh={driverApi}
            showdriveinfo={showdriveinfo}
            isDriver={true}
          />
        </Modal>
      )}
      {showOwnerInfo && (
        <Modal onClose={() => setShowOwnerInfo(false)}>
          <OwnerInfomation
            ownerInfo={ownerInfo}
            setShowOwnerInfo={setShowOwnerInfo}
            refresh={ownerApi}
            showVihicleInfo={showVihicleInfo}
            showOwnerInfo={showOwnerInfo}
          />
        </Modal>
      )}
    </Layout>
  );
};


// Helper Components
const StatCard = ({ title, value, icon }) => (
  <div className="bg-white rounded-lg shadow p-6 flex items-center">
    <div className="text-4xl mr-4">{icon}</div>
    <div>
      <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
      <p className="text-3xl font-bold text-gray-500">{value}</p>
    </div>
  </div>
);

const VerificationList = ({ title, data, searchValue, onSearchChange, onRefresh, onEdit }) => (
  <div className="bg-white shadow rounded-lg p-6">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
      <div className="flex items-center">
        <input
          className="rounded p-2 bg-gray-100 focus:outline-none text-sm mr-2"
          type="text"
          value={searchValue}
          onChange={onSearchChange}
          placeholder="Search by Name / Phone..."
        />
        <button onClick={onRefresh} className="p-2 bg-gray-100 rounded hover:bg-gray-200">
          <RefreshIcon />
        </button>
      </div>
    </div>
    <ul className="divide-y divide-gray-200 max-h-80 overflow-y-auto">
      {data.map((item, index) => (
        <li key={index} className="py-4 flex items-center justify-between">
          <div className="flex items-center">
            <ImageS3 uri={item?.profile} style="h-10 w-10 rounded-full mr-3" />
            <div>
              <p className="text-sm font-medium text-gray-900">{item?.name}</p>
              <p className="text-sm text-gray-500">{item?.mobile}</p>
            </div>
          </div>
          <button
            onClick={() => onEdit(item)}
            className="text-indigo-600 hover:text-indigo-900 font-medium"
          >
            Edit
          </button>
        </li>
      ))}
    </ul>
  </div>
);

const Modal = ({ children, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
    <div className="bg-white rounded-lg max-w-xl w-full max-h-[90vh] overflow-y-auto">
      <div className="p-6">
        {children}
      </div>
      <div className="border-t px-6 py-3 flex justify-end">
        <button
          onClick={onClose}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
        >
          Close
        </button>
      </div>
    </div>
  </div>
);

const RefreshIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 4V9H4.58152M19.9381 11C19.446 7.05369 16.0796 4 12 4C8.64262 4 5.76829 6.06817 4.58152 9M4.58152 9H9M20 20V15H19.4185M19.4185 15C18.2317 17.9318 15.3574 20 12 20C7.92038 20 4.55399 16.9463 4.06189 13M19.4185 15H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Dashboard;
