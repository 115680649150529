import React, { useState } from "react";
import { Button } from "react-chat-elements";
import ReactModal from "react-modal";
import AdminService from "../../services/admin/AdminService";
import moment from "moment";
import ImageS3 from "../../components/common/ImageS3";

export default function InitiatedTrip(props) {
  const [open, setOpen] = useState(false);
  const [bidInfo, setBidInfo] = useState();
  const [openIndex, setOpenIndex] = useState();

  console.log("data in InitiatedTrip==>", props.data);

  const bids = async (id) => {
    const data = await AdminService.bids(id);
    setBidInfo(data.data.bids);
    setOpen(!open);
  };

  console.log("bidInfo", bidInfo);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="mx-auto px-4 py-8">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Trip Id</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">From</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">To</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bid Count</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {props.data && props.data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">{item._id}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{item.fromLocation.name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{item.toLocation.name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{item.startDate.substr(0, 10)}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{item.startTime}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{item.bidCount}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => bids(item._id)}
                      className="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
                    >
                      View Bids
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ReactModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        // className="modal"
        // overlayClassName="modal-overlay"
        style={{
          content: {
            border: 'none',
            // transform: 'translate(-50%, -50%)',
          },
          // overlay: {
          //   backgroundColor: 'rgba(0, 0, 0, 0.75)'
          // }
        }}
      >
        <div className=" rounded-lg shadow-xl w-full mx-auto bg-blue-100">
          <div className="flex justify-between items-center p-6 border-b">
            <h2 className="text-2xl font-bold text-gray-900">Bid Information Here</h2>
            <button onClick={() => setOpen(false)} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Close</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="p-6 overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">No.</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">BID Id</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fare</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Owner</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Driver</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Car model</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Car No.</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {bidInfo && bidInfo.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item._id}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.bidFare.amount}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.bidBy.owner.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {item.bidBy.driver.name} - {" "}
                        <button onClick={() => toggleAccordion(index)} className="text-indigo-600 hover:text-indigo-900">
                          View Details
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.vehicleDetails.vehicle.carInfo.model} - {item.vehicleDetails.vehicle.carInfo.year}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.vehicleDetails.vehicle.registration.vehicleNumber}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss A")}
                      </td>
                    </tr>
                    {openIndex === index && (
                      <tr>
                        <td colSpan="8" className="px-6 py-4 bg-gray-50">
                          <div className="flex space-x-4">
                            <div className="flex flex-col items-center justify-center shadow-lg p-2">
                              Profile
                              <ImageS3 uri={item.bidBy.driver.profile} style="h-20 w-20 rounded" />
                            </div>
                            <div className="flex flex-col items-center justify-center shadow-lg p-2">
                              Driving License
                              <ImageS3 uri={item.bidBy.driver.docs.drivingLicense.uri} style="h-20 w-20 rounded" />
                            </div>
                            <div className="flex flex-col items-center justify-center shadow-lg p-2">
                              NID
                              <ImageS3 uri={item.bidBy.driver.docs.nidFont.uri} style="h-20 w-20 rounded" />
                            </div>
                          </div>
                          <div className="mt-4 space-y-2">
                            <p className="text-sm text-gray-600">ID: {item.bidBy.driver._id}</p>
                            <p className="text-sm text-gray-600">Name: {item.bidBy.driver.name}</p>
                            <p className="text-sm text-gray-600">Mobile: {item.bidBy.driver.mobile}</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
