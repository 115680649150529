
// export const API_BASE_URL = 'https://api.uturntrip.com/api';
// export const API_BASE_URL = 'http://uturntrip.com:3000/api';
export const API_BASE_URL = 'http://localhost:3000/api'; // home
// export const API_BASE_URL = 'http://192.168.98.230:3000/api'; // home
// export const API_BASE_URL = 'http://192.168.0.103:3000/api'; // home
// export const API_BASE_URL = 'http://192.168.98.220:3000/api'; // home
// export const API_BASE_URL = 'http://192.168.245.93:3000/api'; // home


export const AUTH_KEY = 'u_turn_auth_key';


export const HELP_LINE = '+8801515272948';
